import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';

import { DateOptionsConfig } from '@@shared/sl-table/models/sl-table.model';

@Component({
	selector: 'sl-column-template-date-range',
	templateUrl: './column-template-date-range.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		TooltipModule,
		DatePipe,
	],
})
export class ColumnTemplateDateRangeComponent {
	readonly optionsSignal$ = input<DateOptionsConfig>(null, { alias: 'options' });
	readonly dataSignal$ = input(null, { alias: 'data' });
}
