import { ChangeDetectionStrategy, Component, contentChild, input, TemplateRef } from '@angular/core';

@Component({
	selector: 'sl-custom-column-template',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class CustomColumnTemplateComponent {
	readonly columnSignal$ = input<string>(null, { alias: 'column' });

	readonly templateRef = contentChild.required<TemplateRef<any>>(TemplateRef);
}
