/* eslint-disable @typescript-eslint/member-ordering */
import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';

import { FETCHING_DATA_INTERVAL_TIME, QueryDataStatus } from '@@shared/common/models/api.model';

import { SetupTableResponse, SLTableColumnDef, SLTableConfig, SLTableEmptyStateReason, TableResponse } from '../models/sl-table.model';
import { initialTableState, TableState } from '../models/table.store.models';
import { SlTableService } from '../services/sl-table.service';

interface FetchDataParams<Req, Res> {
	config: SLTableConfig<Req, Res>;
	forceUpdateColumns: boolean;
}

@Injectable({
	providedIn: null
})
export class TableStore<Req, Res> extends ComponentStore<TableState<Req, Res>> {

	readonly #tableService = inject(SlTableService);

	constructor() {
		super(initialTableState<Req, Res>());
	}

	readonly setLoading = this.updater((state, loading: boolean): TableState<Req, Res> => ({
		...state,
		loading
	}));

	readonly setData = this.updater((state, data: TableResponse<Res>): TableState<Req, Res> => ({
		...state,
		tableData: data
	}));

	readonly setColumns = this.updater((state, columns: SLTableColumnDef<Req, Res>[]): TableState<Req, Res> => ({
		...state,
		columns
	}));

	readonly setEmptyStateReason = this.updater((state, emptyStateReason: SLTableEmptyStateReason): TableState<Req, Res> => ({
		...state,
		emptyStateReason
	}));

	readonly fetchData = this.effect((params$: Observable<FetchDataParams<Req, Res>>) =>
		params$.pipe(
			tap(() => this.setLoading(true)),
			switchMap(({ config, forceUpdateColumns }) =>
				this.#tableService.setupTable(config).pipe(
					tap((response: SetupTableResponse<Req, Res>) => {
						if (response.tableData.data.status === QueryDataStatus.InProgress) {
							setTimeout(() => {
								this.fetchData({ config, forceUpdateColumns });
							}, FETCHING_DATA_INTERVAL_TIME);
							return;
						}
						this.setData(response.tableData);
						if (!this.selectSignal(state => state.columns)().length || forceUpdateColumns) {
							this.setColumns(response.columns);
						}
						const emptyStateReason: SLTableEmptyStateReason = this.#tableService.getEmptyStateReason(config.dataSourceConfig.defaultQuery, response.tableData);
						this.setEmptyStateReason(emptyStateReason);
						this.setLoading(false);
					}),
					catchError((err) => {
						this.setLoading(false);
						return of(err);
					})
				)
			)
		)
	);
}
