import { SLTableColumnDef, SLTableEmptyStateReason, SLTableFilterTypeConfig, SLTableOptionsTypeConfig, TableResponse } from './sl-table.model';

export interface TableState<Req, Res> {
	tableData: TableResponse<Res>;
	columns: SLTableColumnDef<Req, Res, SLTableFilterTypeConfig<Req, Res>, SLTableOptionsTypeConfig>[];
	loading: boolean;
	emptyStateReason: SLTableEmptyStateReason;
}

export const initialTableState = <Req, Res>(): TableState<Req, Res> => ({
	tableData: {
		data: {
			total: 0,
			next: null,
			previous: null,
			list: []
		}
	},
	columns: [],
	loading: true,
	emptyStateReason: null
});
