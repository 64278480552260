import { ChangeDetectionStrategy, Component, contentChild, TemplateRef } from '@angular/core';

@Component({
	selector: 'sl-custom-row-expansion',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class CustomRowExpansionComponent {
	readonly templateRef = contentChild.required<TemplateRef<any>>(TemplateRef);
}
