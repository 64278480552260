import { ChangeDetectionStrategy, Component, contentChild, input, TemplateRef } from '@angular/core';

import { TableData } from '@@shared/sl-table/models/sl-table.model';

@Component({
	selector: 'sl-custom-header',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class CustomHeaderTemplateComponent {
	readonly dataSignal$ = input<TableData<any>>(null, { alias: 'data' });

	readonly templateRef = contentChild.required<TemplateRef<any>>(TemplateRef);
}
