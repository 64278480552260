@if (configSignal$()) {
	<div class="sl-table-wrapper">
	@switch (tableData$()?.status) {
		@case (QueryDataStatus.Error) {
			<sl-empty-panel>
				<mat-icon class="icon material-icons-outlined">cancel_outlined</mat-icon>
				<div class="main-text">Loading failed</div>
			</sl-empty-panel>
		}
		@default {
			<p-table
				#table
				scrollHeight="flex"
				[class]="['sl-v1-table-primeng', configSignal$().className || '', emptyStateReason$() || '']"
				[dataKey]="configSignal$().features?.rowExpansion?.dataKey"
				[selectionMode]="configSignal$().features?.enableRowSelection ? 'multiple' : null"
				[loading]="loading$()"
				[value]="tableData$()?.list"
				[columns]="selectedColumns$()"
				[showInitialSortBadge]="true"
				[showLoader]="true"
				[scrollable]="true"
				[rowHover]="true"
				[lazy]="true"
				[resizableColumns]="configSignal$().features?.resizableColumns"
				[sortField]="configSignal$().dataSourceConfig?.defaultQuery?.sortBy[0]"
				[sortOrder]="configSignal$().dataSourceConfig?.defaultQuery?.sortDirection[0] === SortDirection.ASC ? 1 : -1"
				[selectionPageOnly]="true"
				[(selection)]="selectedRows"
				(onLazyLoad)="onSort($event)"
				(selectionChange)="onSelectionChange($event)"
				(onHeaderCheckboxToggle)="selectAllChange($event)">

				@if (!configSignal$().features.hideHeader) {
					<ng-template pTemplate="caption">
						<div class="sl-flexbox-column sl-width-100">
							<div class="sl-flexbox-row sl-width-100">
						@if (headerTemplate) {
							<ng-container
								[ngTemplateOutlet]="headerTemplate"
								[ngTemplateOutletContext]="{ $implicit: tableData$() }">
							</ng-container>
						}
						<span class="sl-typography-body-medium"	[hidden]="!selectAll">
							{{ selectedRows.length }} of {{ tableData$()?.total }} entries are selected
						</span>
						<div class="sl-flexbox-row sl-flexbox-justify-end sl-flexbox-grow sl-flexbox-align-center sl-flexbox-gap-16">
							@if (showFilterPanel$()) {
								<sl-filter-panel
									[activeFilters]="activeFilterSignal$()"
									[inputDebounce]="configSignal$().features?.filterDebounceTime"
									[columns]="columnsSignal$()"
									(filterChange)="onFilterChange($event)">
								</sl-filter-panel>
							}
							@if (showFilterPanel$() && (showSearchBox$() || showTableSettings$())) {
								<div class="table-divider sl-dark-background-400">|</div>
							}
							@if (showSearchBox$()) {
								<sl-search-box
									[placeholder]="configSignal$().features.searchBox.placeholder ?? 'Search...'"
									[value]="searchTerm"
									[standalone]="true"
									[inputDebounce]="configSignal$().features?.filterDebounceTime"
									(searchChange)="onSearchChange($event)">
								</sl-search-box>
							}
							@if (showSearchBox$() && showTableSettings$()) {
								<div class="table-divider sl-dark-background-400">|</div>
							}
							@if (showTableSettings$()) {
								<sl-table-settings
									[columnsOrdering]="configSignal$().features?.columnsOrdering"
									[stickyColumns]="configSignal$().features?.stickyColumns"
									[columns]="columnsSignal$()"
									(columnsChange)="manageColumnOrderAndVisibility($event)">
								</sl-table-settings>
							}
							@if (configSignal$().features?.headerActions?.length > 0) {
								<ng-container *ngTemplateOutlet="headerActionsTemplate; context: { actionItem: configSignal$().features?.headerActions }">
								</ng-container>
							}
							</div>
						</div>
							<ng-content [select]="toolbar-extension"></ng-content>
						</div>
					</ng-template>
				}

				<ng-template pTemplate="header">
					<tr>
						<th
							class="th_checkbox"
							alignFrozen="left"
							pFrozenColumn
							[hidden]="!(configSignal$().features?.enableRowSelection && configSignal$().features?.enableHeaderSelection)"
							[frozen]="true">
							<p-tableHeaderCheckbox class="sl-v1"></p-tableHeaderCheckbox>
						</th>
						<th
							class="th_expand"
							pFrozenColumn
							alignFrozen="left"
							[hidden]="!configSignal$().features?.rowExpansion?.enable"
							[frozen]="true">
						</th>
						@for (col of selectedColumns$(); track col) {
							<th [style]="col.style"
								pResizableColumn
								pFrozenColumn
								alignFrozen="left"
								[pSortableColumn]="configSignal$().features?.sorting && col.isSortable ? col.field : false"
								[pResizableColumnDisabled]="!col.isResizable"
								[frozen]="configSignal$().features?.stickyColumns && col.isSticky">
								<div class="sl-flexbox sl-flexbox-align-center">
									@if (col.header.icon) {
										<mat-icon
											class="material-icons-outlined lg"
											tooltipStyleClass="sl-v1-tooltip"
											tooltipPosition="bottom"
											[pTooltip]="col.header.tooltip">
											{{ col.header.icon }}
										</mat-icon>
									}
									@if (!col.header.hideDisplayName) {
										<span>{{ col.header.displayName }}</span>
									}
									<p-sortIcon [hidden]="!(configSignal$().features?.sorting && col.isSortable)" [field]="col.field"></p-sortIcon>
								</div>
							</th>
						}
						<th
							class="actions"
							pFrozenColumn
							alignFrozen="right"
							[hidden]="!configSignal$().features?.enableActionsColumn"
							[frozen]="true">
						</th>
					</tr>
				</ng-template>

				<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded">
					<tr>
						<td
							class="td_checkbox"
							alignFrozen="left"
							pFrozenColumn
							[hidden]="!configSignal$().features?.enableRowSelection"
							[frozen]="true">
							<p-tableCheckbox class="sl-v1" [value]="rowData"></p-tableCheckbox>
						</td>
						<td
							class="td_expand"
							pFrozenColumn
							alignFrozen="left"
							[hidden]="!configSignal$().features?.rowExpansion?.enable"
							[pRowToggler]="rowData"
							[frozen]="true">
							<span class="pi clickable" ngClass="{{ expanded ? 'pi-angle-up' : 'pi-angle-down' }}"></span>
						</td>
						@for (col of selectedColumns$(); track col) {
							<td class="ellipsisize sl-typography-body-medium type_{{ col?.type }} td_{{ col.field }}"
								pFrozenColumn
								alignFrozen="left"
								[style]="col.style"
								[frozen]="configSignal$().features?.stickyColumns && col.isSticky">
								@switch (col.type) {
									@case (ColumnType.CUSTOM) {
										@if (customTemplatesMap && customTemplatesMap.get(col.field)) {
											<ng-container
												[ngTemplateOutletContext]="{ $implicit: rowData }"
												[ngTemplateOutlet]="customTemplatesMap.get(col.field)">
											</ng-container>
										}
									}

									@case (ColumnType.DATE_RANGE) {
										<sl-column-template-date-range [data]="rowData" [options]="col?.metaData?.options"></sl-column-template-date-range>
									}

									@default {
										<div class="ellipsisize">{{ rowData[col.field] }}</div>
									}
								}
							</td>
						}
						<td
							class="actions"
							alignFrozen="right"
							pFrozenColumn
							[hidden]="!configSignal$().features?.enableActionsColumn"
							[frozen]="true">
							<div class="sl-flexbox sl-flexbox-justify-end">
								<ng-container *ngTemplateOutlet="rowActionsTemplate; context: { $implicit: rowData }"></ng-container>
							</div>
						</td>
					</tr>
				</ng-template>

				<ng-template pTemplate="rowexpansion" let-rowData>
					<tr>
						<td colspan="12">
							<div class="p-3">
								<ng-container *ngTemplateOutlet="rowExpansionTemplate; context: { $implicit: rowData }"></ng-container>
							</div>
						</td>
					</tr>
				</ng-template>

				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="12" class="no-border">
							@switch (emptyStateReason$()) {
								@case (EmptyStateReason.NoData) {
									@if (emptyStateTemplatesMap?.get(EmptyStateReason.NoData)) {
										<ng-container [ngTemplateOutlet]="emptyStateTemplatesMap.get(EmptyStateReason.NoData)"></ng-container>
									}
								}

								@case (EmptyStateReason.NoDataDueToFilters) {
									@if (emptyStateTemplatesMap?.get(EmptyStateReason.NoDataDueToFilters)) {
										<ng-container [ngTemplateOutlet]="emptyStateTemplatesMap.get(EmptyStateReason.NoDataDueToFilters)"></ng-container>
									}
								}

								@default {
									<div>No data found</div>
								}
							}
						</td>
					</tr>
				</ng-template>

				<ng-template pTemplate="loadingicon">
					<tr>
						<td colspan="12">
							<div class="table-spinner"></div>
						</td>
					</tr>
				</ng-template>

				<ng-template pTemplate="summary">
					<p-paginator styleClass="sl-paginator"
						dropdownAppendTo="body"
						[first]="configSignal$().dataSourceConfig.defaultQuery.offset"
						[rows]="configSignal$().dataSourceConfig.defaultQuery.limit"
						[totalRecords]="tableData$()?.total"
						[rowsPerPageOptions]="configSignal$().features.pageSizeOptions || DEFAULT_PAGE_SIZE_OPTIONS"
						(onPageChange)="onPageChange($event)">
					</p-paginator>
				</ng-template>

			</p-table>
		}
	}

		<ng-template #headerActionsTemplate let-actionItem="actionItem">
			<div class="header-actions-wrapper sl-flexbox-row sl-flexbox-gap-16">
				@for (actionItem of actionItem; track actionItem) {
					<div 	class="sl-flexbox-row sl-flexbox-align-center"
							[class.not-displayed]="actionItem.hidden"
							[tooltipPosition]="actionItem.tooltipPosition || 'top'"
							tooltipStyleClass="sl-v1-tooltip sl-v1-tooltip-no-wrap"
							[pTooltip]="headerActionsDisabledState[actionItem.id] ? actionItem.disabledTooltip: actionItem.tooltip"
							[tooltipDisabled]="false">

						<!-- Conditionally display a button or a menu based on the configuration -->
						@if (actionItem?.items?.length > 0) {
							<p-button 	[class.events-none]="headerActionsDisabledState[actionItem.id]"
										styleClass="sl-v1-button sl-v1-button-icon"
										severity="filter"
										(onClick)="menu.toggle($event)">
								<mat-icon class="mat-icon-20 material-symbols-outlined">
									{{ actionItem.icon }}
								</mat-icon>
							</p-button>
							<p-menu #menu styleClass="sl-v1-menu" appendTo="body" [model]="actionItem.items" [popup]="true">
								<ng-template pTemplate="item" let-item>
									<span [pTooltip]="item.tooltip" tooltipStyleClass="sl-v1-tooltip">
										<a class="p-menuitem-link sl-flexbox">
											<span class="sl-typography-body-medium">{{ item.label }}</span>
										</a>
									</span>
								</ng-template>
							</p-menu>
						}
						@else {
							<p-button 	[class.events-none]="headerActionsDisabledState[actionItem.id]"
										styleClass="sl-v1-button sl-v1-button-icon"
										severity="filter"
										(onClick)="onTableHeaderActionExecute(actionItem, $event)">
								<mat-icon class="mat-icon-20 material-symbols-outlined">
									{{ actionItem.icon }}
								</mat-icon>
							</p-button>
						}
					</div>
				}
			</div>
		</ng-template>
	</div>
}
