import { Directive, inject, input, TemplateRef } from '@angular/core';

import { SLTableEmptyStateReason } from '../models/sl-table.model';

@Directive({
	selector: '[emptyStateTemplate]',
	standalone: true
})
export class EmptyStateTemplateDirective {
	readonly typeSignal$ = input<SLTableEmptyStateReason>(null, { alias: 'type' });

	readonly templateRef = inject(TemplateRef);
}
