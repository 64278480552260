@if (dataSignal$()[optionsSignal$()?.from_field] && dataSignal$()[optionsSignal$()?.to_field]) {
	<div	class="ellipsisize sl-width-auto"
			[pTooltip]="(dataSignal$()[optionsSignal$()?.from_field] | date:'fullDate':'UTC') + ' - ' + (dataSignal$()[optionsSignal$()?.to_field] | date:'fullDate':'UTC')"
			tooltipPosition="bottom"
			tooltipStyleClass="sl-v1-tooltip sl-v1-tooltip-no-wrap">
		<span>{{ (dataSignal$()[optionsSignal$()?.from_field] | date:'MMM d, yyyy':'UTC') }}</span>
		<span> - </span>
		<span>{{ dataSignal$()[optionsSignal$()?.to_field] | date:'MMM d, yyyy':'UTC' }}</span>
	</div>
}
